import Image from "next/image"
import ArticleCard from "@/common/cards/article/article"
import MarkingAreaCard from "@/common/cards/marking-area/marking-area"
import Slider from "@/common/slider/slider"
import Block from "@/components/wrappers/block"
import styles from "./post-slider.module.css"
import cx from "classnames"

export default function PostSlider({ block }) {
  const { cards, heading, text, theme, image, button, cardType } = block.attrs

  if (cards.length === 0) {
    return null
  }

  const wrapperClasses = [
    styles.postSlider,
    styles[theme],
    theme === "image" ? styles.overlay : ""
  ]

  return (
    <Block block={block} width="bleed" className={cx(wrapperClasses)}>
      {image && (
        <Image
          className="object-cover"
          src={image.url}
          alt={image.alt}
          sizes={image.sizes}
          fill={true}
        />
      )}
      <Slider
        id={block.attrs.context}
        heading={heading}
        text={text}
        theme={theme}
        button={button}>
        {cards.map((post, index) => {
          let Component = null

          switch (cardType) {
            case "article":
              Component = ArticleCard
              break
            case "marking-area":
              Component = MarkingAreaCard
              break
          }

          return <Component key={index} post={post} />
        })}
      </Slider>
    </Block>
  )
}
